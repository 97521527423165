import React, {useEffect, useRef, useState} from "react";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";

import Header from "../header";
import HomeBanner from "../homeBanner";
import BookingForms from "../bookingForms";
import CarCarousel from "../carCarousel";
import Footer from "../footer";
import SEO from "../seo";
import MapAndAddress from "../mapAndAddress";
import { _osTrackingEvent } from "./_osTrackingEvent";
import { getCookie } from '../cookieUtils';

import "../../css/layout.css";

function setCookie(cookieName, cookieValue, minutesToExpire) {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + (minutesToExpire * 60 * 1000));
  const expires = "expires=" + expirationDate.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}
const Layout = (props) => {
  const {
    pageContext: {
      Address,
      City,
      ClientID,
      ConversionPixelID,
      Dealer_Name,
      FB_Page_link,
      GMB_Link,
      Latitude,
      Longitude,
      PixelID,
      Zone,
      Region,
      State,
      Virtual_Numbers,
      carName,
      carIndex,
      carDisplayName,
      isExperimental,
    },
  } = props;
  const [strParam, setStrParam] = useQueryParam("name", StringParam);
  const [cityParam, setCityParam] = useQueryParam("city", StringParam);
  const [stateParam, setStateParam] = useQueryParam("state", StringParam);

  const [hasMounted, setHasMounted] = useState(false);

  const [isPerfmax, setIsPerfmax] = useState(false);

  const setUserVisitedCookie = () => {
    setCookie("userVisitedPmax", "true", 30);
  }

  const checkIfUserVisitedFromPmax = () => {
    return getCookie("userVisitedPmax");
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const campaignType = urlParams.get('_camapign_type');
    const isPmaxVisitCookiePresent = checkIfUserVisitedFromPmax();
    if (campaignType === "pmax" || isPmaxVisitCookiePresent) {
      setIsPerfmax(true);

      if (!isPmaxVisitCookiePresent) {
        setUserVisitedCookie();
      }
    }
    setHasMounted(true);
  }, []);

  const setFieldsFlag = useRef(true);

  const handleSetData = () => {
    if (typeof window !== "undefined" && typeof window._osSetIds === "function" && setFieldsFlag.current) {
      window._osSetIds({
        client_id: ClientID,
        googlePixelId: PixelID,
        googleConversionId: ConversionPixelID,
      });

      setFieldsFlag.current = false;

      _osTrackingEvent({
        leadStep: "viewproduct",
        product_names: carDisplayName || "grand_i10_nios"
      })
    }
  };

  useEffect(() => {
    if(setFieldsFlag.current) {
      handleSetData();
    }
  });

  const _osOnPageClick = (event) => {
    try{
      let eventTarget = event?.target;
      let targetClassName = [];
      if(typeof(eventTarget.className) == "string"){
        targetClassName.push(eventTarget.className);
        targetClassName.join("")
      }else{
        for(let i = 0; i< eventTarget.classList.length; i++){
          targetClassName.push(eventTarget.classList[i])
        }
        targetClassName.join(",")
      }
      if(eventTarget){
        let paramObj ={
          leadStep:"onDOMClick",
          product_names: carDisplayName,
          clickDOM:event.target.id || targetClassName || event.target.tagName
        }
        _osTrackingEvent(paramObj)
      }
    }catch(error){

    }
  };

  if (!hasMounted) {
    return null;
  }

  const renderBookingForms = (props) => {
    return (
      <BookingForms
        carDisplayName={carDisplayName}
        city={cityParam}
        state={stateParam}
        name={strParam}
        ClientId={ClientID}
        PixelID={PixelID}
        formGridSize={12}
        isNormalVisit={!isPerfmax}
        formBookingId="footerBookDriveForm"
        {...props}
      />
    )
  }

  return (
    <>
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0,
        }}
        onClick={_osOnPageClick}
      >
        <SEO {...props} />
        <main>
          <Header dealer={Dealer_Name} contact={Virtual_Numbers} isNormalVisit={!isPerfmax} />
          <HomeBanner carName={carName} />
          <CarCarousel carIndex={carIndex} />
          <BookingForms
            carDisplayName={carDisplayName}
            city={cityParam}
            state={stateParam}
            name={strParam}
            ClientId={ClientID}
            PixelID={PixelID}
            isNormalVisit={!isPerfmax}
          />
          <MapAndAddress
            address={Address}
            dealer={Dealer_Name}
            gmbLink={GMB_Link}
            lat={Latitude}
            lng={Longitude}
          />
          <Footer
            isExperimental={isExperimental}
            address={Address}
            lat={Latitude}
            lng={Longitude}
            contact={Virtual_Numbers}
            gmbLink={GMB_Link}
            fbLink={FB_Page_link}
            carDisplayName={carDisplayName}
            isNormalVisit={!isPerfmax}
            bookingFormDom={renderBookingForms}
          />
          <input type="hidden" id="zone-hidden" value={Zone} />
          <input type="hidden" id="region-hidden" value={Region} />
          <input type="hidden" id="city-hidden" value={City} />
          <input type="hidden" id="state-hidden" value={State} />
        </main>
      </div>
    </>
  );
};

export default Layout;
