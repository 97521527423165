let defaultConfig = {
  carList: [
    "Grand i10 NIOS",
    "Grand i10 NIOS CNG",
    "i20",
    // "i20 iMT",
    "i20 N Line",
    // "i20 N Line MT",
    "Aura",
    "Aura CNG",
    "All New Verna",
    "Creta",
    "Venue",
    "Venue N Line",
    "Creta N Line",
    // "Venue iMT",
    "Tucson",
    // "Kona",
    "Alcazar",
    "EXTER",
    "EXTER CNG",
  ],
};

export default defaultConfig;
