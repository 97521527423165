const signInWithPhoneNumber = async (data) => {
  try {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const responsePromise = await fetch("https://otp.onlinesales.ai/send", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      headers,
    });
    if (responsePromise.ok) {
      return await responsePromise.json();
    } else {
      throw new Error("Unable to send otp");
    }
  } catch (error) {
    throw error;
  }
};

const verifyOtp = async (data) => {
  try {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const responsePromise = await fetch("https://otp.onlinesales.ai/verify", {
      method: "POST",
      mode: "cors",
      headers,
      body: JSON.stringify(data),
    });
    if (responsePromise.ok) {
      return await responsePromise.json();
    } else {
      throw new Error("Unable to verify otp");
    }
  } catch (error) {
    throw error;
  }
};

export { signInWithPhoneNumber, verifyOtp };
