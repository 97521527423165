/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";

function SEO({ pageContext }) {
  const {
    Address,
    City,
    Dealer_Name,
    Zone,
    Region,
    State,
    carDisplayNames,
    Website,
  } = pageContext;

  const lang = "en_IN";
  const description = `${Dealer_Name} is the best Hyundai Dealer and has authorized Hyundai Showroom(s) in ${City}. Visit our Showroom(s) for best prices and offers. ${Dealer_Name} is the best Hyundai Dealer to help you in all your Hyundai car requirements. Visit us to get the best quote for on-road price and offers on ${Object.values(
    carDisplayNames
  )
    .map((car) => `Hyundai ${car}`)
    .join(", ")} in ${City}.`;

  const title = `${Dealer_Name} | Hyundai Showroom in ${City}`;
  const keywords = [
    "Hyundai",
    "Car",
    "Automobile",
    "Vehicle",
    `Hyundai cars in ${City}`,
    City,
    "Car dealership near me",
    `cars in ${City}`,
    "Sedan",
    "SUV",
    "used cars near me",
    `Authorised Hyundai Dealer in ${City}`,
    `Official car dealer in ${City}`,
    `used cars dealers in ${City}`,
    `${Dealer_Name} ${City}`,
    "Customer care services",
    "Car dealer near me",
    `nearest car dealer in ${City}`,
    ...Object.values(carDisplayNames),
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: "title",
          content: title,
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: "author",
          content: Dealer_Name,
        },
        {
          name: "keywords",
          content: keywords.join(", "),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: "og:url",
          content: Website,
        },
        {
          property: "og:site_name",
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:locale",
          content: lang,
        },
        {
          property: "og:street-address",
          content: Address,
        },
        {
          property: "og:locality",
          content: City,
        },
        {
          property: "og:region",
          content: Region,
        },
        {
          property: "og:zone",
          content: Zone,
        },
        {
          property: "og:state",
          content: State,
        },
        {
          property: "og:country-name",
          content: "india",
        },
        {
          property: "og:tags",
          content: keywords.join(", "),
        },
        {
          name: `twitter:creator`,
          content: Dealer_Name,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:url`,
          content: Website,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
      ]}
    />
  );
}

export default SEO;
