/* eslint-disable */
import React from 'react';
import { useMediaQuery } from "./hooks";

const DeviceDetection = (Component, inputWidth) => {
  return (props) => {
    const isMobile = useMediaQuery(inputWidth);

    return <Component isMobile={isMobile} {...props} />;
  };
};

export default DeviceDetection;