import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { getCookie } from './cookieUtils';
import defaultConfig from "../commonConfig";
import { signInWithPhoneNumber, verifyOtp } from "../api";

import "react-toastify/dist/ReactToastify.css";

const defaultMinutes = 3;
const defaultSeconds = 0;
const PROVIDER = "EXOTEL";
const AGENCY_NAME = "hyundai";
export default class BookingTestDrive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ClientID: this.props.ClientId,
      PixelID: this.props.PixelID,
      isShowSucceesMsg:null,
      isShowError : false,

      verifiedOtp: false,
      isMobileNumberValid: false,
      enableResendOtpBtn: false,
      enableLoader: false, // send otp loader
      verificationLoader: false,
      isOtpSent: false,
      showTimer: false,
      seconds: defaultSeconds,
      minutes: defaultMinutes,
      myCookieValue: null,

      validation:{
        name:{
          regExp:"/^([A-Z])$/",
          value:""
        },
        email:{
          regExp:"/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/",
          value:""
        },
        mobile:{
          regExp:"/^[6-9]\d{9}$/",
          value:""
        }
      },

      errorMsg: {
         forFullNameError : "",
         forEmailError : "",
         forContactError : "",
         forPurchasePeriodError : "",
         forTandcCheckError: ""
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.showSubmitSuccessText = this.showSubmitSuccessText.bind(this)
    this.formValidation = this.formValidation.bind(this);

    this.onSignInSubmit = this.onSignInSubmit.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.showTimerComponent = this.showTimerComponent.bind(this);
  }

  componentDidMount() {
    try {
      const myCookieValue = getCookie('userVisitedPmax');
      this.setState({ myCookieValue });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  };

  showTimerComponent = function () {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);

          this.setState({
            seconds: defaultSeconds,
            minutes: defaultMinutes,
            showTimer: false,
          });
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  };

  onSignInSubmit = function () {
    this.setState({ enableLoader: true }, () => {
    let formElement = document.getElementById(this.props.formId);
    const data = new FormData(formElement);
    let contact = data.get("mobile");
    const phoneNumber = this.props.countryCode + contact;
    signInWithPhoneNumber({
      provider: PROVIDER,
      mobile_number: phoneNumber,
      client: AGENCY_NAME,
    })
      .then((signInResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.otpMetadata = signInResult?.metadata || {};

        toast.success(
          "OTP number has been sent on entered mobile number. Please enter the OTP number in the field below",
          {
            position: "top-center",
          }
        );
        this.showTimerComponent();

        this.setState({
          isOtpSent: true,
          enableResendOtpBtn: true,
          showTimer: true,
          enableLoader: false,
        });

        if (window && window.gtag) {
          window.gtag("event", "otp_requested", {
            car: this.props.carDisplayName,
          });
        }
      })
      .catch((error) => {
        toast.error(
          this.getErrorCodeToMessage(error?.code) || "Error while sending OTP",
          {
            position: "top-center",
          }
        );
        this.setState({ enableLoader: false, showTimer: false });
        // Error; SMS not sent
      });
    });
  };

  verifyCode = function (event) {
    event.preventDefault();
    let formElement = document.getElementById(this.props.formId);
    const data = new FormData(formElement);
    let otp = data.get("otp");
    this.setState({
      verificationLoader: true,
    },
    () => {
      verifyOtp({
        provider: PROVIDER,
        otp,
        metadata: window?.otpMetadata,
        client: AGENCY_NAME,
      })
      .then((result) => {
        // User signed in successfully.
        toast.success("Vertification Done", {
          position: "top-center",
        });
        this.setState({
          verificationLoader: false,
        });
        this.setState({ verifiedOtp: true }, () => {
          this.handleInput();
          setTimeout(() => {
            this.setState({
              showTimer: false,
            });
          }, 1000);
        });

        if (window && window.gtag) {
          window.gtag("event", "otp_verified", {
            car: this.props.carDisplayName,
          });
        }
        // ...
      })
      .catch((error) => {
        this.setState({
          verificationLoader: false,
        });
        toast.error("Invalid OTP", {
          position: "top-center",
        });
        // User couldn't sign in (bad verification code?)
        // ...
      });
    });
  };

  handleInput = function() {
    let formElement = document.getElementById(this.props.formId);
    if (this.state.isShowError && formElement) {
      const data = new FormData(formElement);
      let fullName = data.get("name");
      let email = data.get("email");
      let contact = data.get("mobile");
      let purchasePeriod = data.get("purchasePeriod");
      let tandcCheck = data.get("tandcCheck");
      this.formValidation(
        fullName,
        email,
        contact,
        purchasePeriod,
        tandcCheck
      );
    }
    if (this.state.myCookieValue) {
      const data = new FormData(formElement);
      let contact = data.get("mobile");
      if (contact?.length >= 10) {
        this.setState({
          isMobileNumberValid: true,
        });
      } else {
        this.setState({
          isMobileNumberValid: false,
        });
      }
    }
  };

  formValidation = function (fullName, email, contact, purchasePeriod, tandcCheck) {
    let emailRegex = new RegExp(
      "^[a-z0-9._\\-+]+@[a-z0-9_\\-]+(\\.[a-z0-9]{2,4}){1,2}$"
    );
    let nameRegex = new RegExp("^[a-zA-Z\\s]{2,50}$");
    let contactRegex = new RegExp("^(\\+91)?[6-9][0-9]{9}$");
    let flag = true;
    let fullNameError = "";
    let emailError = "";
    let contactError = "";
    let purchasePeriodError = "";
    let tandcCheckError = "";
    let contactOtpError = "";

    if (!fullName) {
      fullNameError = "Please enter your name";
      flag = false;
    } else if(!nameRegex.test(fullName)){
      fullNameError = "The name should include alphabets only and should be between 2 to 50 characters.";
      flag = false;
    }

    if (!email) {
      emailError = "Please enter your email address";
      flag = false;
    } else if (!emailRegex.test(email)) {
      emailError = "Please enter a valid email address";
      flag = false;
    }

    if (!contact) {
      contactError = "Please enter your mobile number"
      flag = false;
    } else if (!contactRegex.test(contact)) {
      contactError = "Please enter a valid 10 digit mobile number";
      flag = false;
    } else if (contact === "9876543210") {
      contactError = "Please enter a valid mobile number";
      flag = false;
    } else if (
      `${contact || ""}`.length > 1 &&
      `${contact || ""}`.split("").every((v) => v === `${contact[0] || ""}`)
    ) {
      contactError = "Please enter a valid mobile number";
      flag = false;
    } else if (window.localStorage.getItem('mobileNumber') === contact) {
      contactError = "You have already submitted the lead with this mobile number";
      flag = false;
    } else if (!this.state.verifiedOtp && this.state.myCookieValue) {
      contactOtpError = "Please enter OTP and click on verify button";
      flag = false;
    }

    if(!purchasePeriod){
      purchasePeriodError = "Please select your purchase period";
      flag = false;
    }

    if(!tandcCheck){
      tandcCheckError = "Please accept the terms and conditions";
      flag = false;
    }
   
    this.setState({
      isShowError: true,
      errorMsg: {
        forFullNameError: fullNameError,
        forContactError: contactError,
        forEmailError: emailError,
        forPurchasePeriodError: purchasePeriodError,
        forTandcCheckError: tandcCheckError,
        forContactOtpError: contactOtpError,
      }
    });
  
    return flag;
  };

  handleSubmit = function (event) {
    event.preventDefault();
    const data = new FormData(event.target);

    let fullName = data.get("name");
    let email = data.get("email");
    let contact = data.get("mobile");

    email = email.trim();
    contact = contact.trim();
    fullName = fullName.trim();

    let purchasePeriod = data.get("purchasePeriod");
    let selectCar = data.get("selectCar");
    let tandcCheck = data.get("tandcCheck");
    if (this.formValidation(fullName, email, contact, purchasePeriod, tandcCheck)) {
      if(window._googleLeadForm) {
        window._googleLeadForm({
          name: fullName,
          mobileNumber: contact,
          email: email,
          purchasePeriod: purchasePeriod,
          model: selectCar
        });
        this.showSubmitSuccessText();
        window.localStorage.setItem('mobileNumber', contact);
        document.getElementById(this.props.formId).reset();

        if (this.state.myCookieValue) {
          this.setState({
            isShowError: false,
            verifiedOtp: false,
            isMobileNumberValid: false,
            enableResendOtpBtn: false,
            enableLoader: false,
            isOtpSent: false,
            showTimer: false,
            minutes: defaultMinutes,
            seconds: defaultSeconds,
          });
        }

        if (window && window.gtag) {
          window.gtag("event", "generate_lead", {});
        }
      }
      else{
        console.error("Error while submitting lead!")
      }
    }
  };
  showSubmitSuccessText = function(){
    this.setState({
     isShowSucceesMsg:true
    }, () => {
     setTimeout(()=>{
      this.setState({
        isShowSucceesMsg:false
      })
     },5000)
    })
  }

  getErrorCodeToMessage = (code) => {
    const errorMap = {
      "auth/too-many-requests": "Too many attempts, Please try again later",
      "auth/code-expired": "OTP has expired, Please request OTP again",
      "auth/invalid-verification-code": "Invalid OTP, Please enter correct OTP",
      "auth/network-request-failed":
        "We encountered network error,  please check your network or try after sometime",
    };

    return code ? errorMap[code] : null;
  };

  renderMobileOtpDom = () => {
    const { minutes, seconds } = this.state;
    return (
      <>
        <div className="mb-3 showconditional otp">
          <label htmlFor="mobile" className="form-label">
            Mobile
          </label>
          <div className="otp-btn">
            <input
              onChange={this.handleInput}
              type="number"
              maxLength={10}
              className="form-control"
              id="mobile"
              placeholder="Mobile Number"
              name="mobile"
              defaultValue={this.props.mobileNumber}
              onKeyPress={this.handleKeyPressForMobile}
              // disabled={this.state.verifiedOtp ? "disabled" : ""}
            />
            {!this.state.enableResendOtpBtn ? (
              <>
                <input
                  type="button"
                  value="Send OTP"
                  onClick={this.onSignInSubmit}
                  className="mobile-otp-btn"
                  disabled={
                    this.state.enableLoader ||
                    !this.state.isMobileNumberValid
                  }
                />
                {this.state.enableLoader ? (
                  <i
                    className="fa fa-spinner fa-spin fa-1x fa-fw spin-icon"
                    aria-hidden="true"
                  ></i>
                ) : null}
              </>
            ) : (
              <>
                <input
                  type="button"
                  value="Resend OTP"
                  onClick={this.onSignInSubmit}
                  className="mobile-otp-btn resend-otp-btn"
                  disabled={
                    this.state.enableLoader ||
                    (!this.state.isMobileNumberValid &&
                      !this.state.verifiedOtp) ||
                    this.state.verifiedOtp
                  }
                />
                {this.state.enableLoader ? (
                  <i
                    className="fa fa-spinner resend-spin fa-spin fa-1x fa-fw spin-icon"
                    aria-hidden="true"
                  ></i>
                ) : null}
              </>
            )}
          </div>
          <div className="formErrorGuidText">
            {this.state.errorMsg.forContactError}
          </div>
        </div>
        <div className="mb-3 showconditional verify">
          <label htmlFor="otp" className="form-label">
            Mobile OTP
          </label>
          <div className="otp-btn">
            <input
              onChange={this.handleInput}
              type="number"
              maxLength={10}
              className="form-control"
              id="otp"
              placeholder="Mobile OTP"
              name="otp"
              onKeyPress={this.handleKeyPressForMobile}
              disabled={
                !this.state.isOtpSent || this.state.verifiedOtp
                  ? "disabled"
                  : ""
              }
            />
            <>
              <input
                type="button"
                className="mobile-otp-btn"
                value={
                  this.state.verifiedOtp ? "Verified" : "Verify"
                }
                disabled={
                  !this.state.isOtpSent ||
                  this.state.verifiedOtp ||
                  !this.state.showTimer ||
                  this.state.verificationLoader
                    ? "disabled"
                    : ""
                }
                onClick={this.verifyCode}
              />
              {this.state.verificationLoader ? (
                <i
                  className="fa fa-spinner fa-spin fa-1x fa-fw spin-icon verify-spin"
                  aria-hidden="true"
                ></i>
              ) : null}

              {this.state.showTimer ? (
                <span className="otptimer">
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds}{" "}
                  Min.
                </span>
              ) : null}
            </>
          </div>
          <div className="formErrorGuidText">
            {this.state.errorMsg.forContactOtpError}
          </div>
        </div>
      </>
    )
  };

  render() {
    const { myCookieValue } = this.state;
    return (
      <div>
        <div className="container test-drive-form">
          <div className="row justify-content-center">
            <div className={`col-md-${this.props.gridSize}`}>
              <ToastContainer />
              <div id="hyundai-otp-button" style={{ visibility: "hidden" }} />
              <form onSubmit={this.handleSubmit} id={this.props.formId} className="driveform">
                <div className="form-row">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      onChange={this.handleInput}
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name*"
                      name="name"
                      defaultValue={this.props.name}
                    />
                    <div className = "formErrorGuidText">
                      {this.state.errorMsg.forFullNameError}
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email address
                    </label>
                    <input
                      onChange={this.handleInput}
                      type="text"
                      className="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Email*"
                      name="email"
                    />
                    {
                      <div className = "formErrorGuidText">
                        {this.state.errorMsg.forEmailError}
                      </div>
                    }
                  </div>

                  {myCookieValue ? (
                      this.renderMobileOtpDom()
                    ) : (
                      <div className="mb-3">
                        <label htmlFor="mobile" className="form-label">
                          Mobile
                        </label>
                        <input
                          onChange={this.handleInput}
                          type="text"
                          className="form-control"
                          id="mobile"
                          placeholder="Mobile*"
                          name="mobile"
                          defaultValue={this.props.mobileNumber}
                        />
                        <div className = "formErrorGuidText">
                          {this.state.errorMsg.forContactError}
                        </div>
                      </div>
                    )}

                  <div className="mb-3">
                    <label htmlFor="selectCar" className="form-label">
                    Car Model
                    </label>
                    <select
                      className="form-control"
                      id="selectCar"
                      name="selectCar"
                      required>
                      {
                        defaultConfig.carList.map((value) => {
                          return(
                            <option selected = {(value === this.props.carDisplayName)}  key = {`${value}`} value={value} >{value}</option>
                          )
                        })
                      }
                    </select>
                    <div className = "formErrorGuidText"/>
                  </div>

                  <div className="mb-3 sr-only">
                    <label htmlFor="purchasePeriod" className="form-label">
                      When to buy
                    </label>
                    <select
                      className={`form-control`}
                      id="purchasePeriod"
                      name="purchasePeriod"
                      defaultValue={this.props.purchasePeriod}
                      onChange={this.handleInput}
                      >
                      {/* <option value="" selected hidden disabled>Choose here*</option> */}
                      <option value="30 days" selected>30 days</option>
                      <option value="45 days">45 days</option>
                      <option value="60 days">60 days</option>
                    </select>
                    <div className = "formErrorGuidText">
                      {this.state.errorMsg.forPurchasePeriodError}
                    </div>
                  </div>

                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      onChange={this.handleInput}
                      className={`form-check-input ${this.state.errorMsg.forTandcCheckError ? "error" : ""}`}
                      id="tandcCheck"
                      name="tandcCheck"
                      checked
                    />
                    <label className="form-check-label" htmlFor="tandcCheck">
                      * I accept the <a href="https://www.hyundai.com/in/en/utility/legal-disclaimer/terms-and-conditions-of-the-website" className="termsCond" target="_blank">terms &amp; conditions</a>
                    </label>
                  </div>
                  <div className="text-center d-grid gap-2">
                    {this.state.isShowSucceesMsg && (
                      <div className="success-msg-block">
                        <p className="text-success">
                          Your Test Drive request has been submitted successfully.
                        </p>
                      </div>
                    )}
                    <button type="submit" className="btn formSubmit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BookingTestDrive.defaultProps = {
  gridSize: 6,
  formId: "bookDrive",
  showTimer: false,
  countryCode: "+91",
};
