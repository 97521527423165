import React, { Component } from "react"
import Address from "./address"
// import GoogleMapComponent from "./googleMap"

import "../css/globalRules.css"

export class MapAndAddress extends Component {
  render() {
    return (
      <div className="mt-5" style={{backgroundColor: '#f2f2f2'}}>
        <ul
          className="nav nav-pills"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation" id="address-pill">
            <button
              className="nav-link active"
              id="map-address"
              data-bs-toggle="pill"
              data-bs-target="#map-address-tab"
              type="button"
              role="tab"
              aria-controls="map-address-tab"
              aria-selected="true"
            >
              Showroom Address
            </button>
          </li>
        </ul>

        <hr className="hr-line" />
        <div className="tab-content mt-3 pb-5 mobileFooterView" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="map-address-tab"
            role="tabpanel"
            aria-labelledby="map-address"
          >
            <div className="container-fluid">
              <div className="row">
                {/* <div className="col-6 desktopView px-3 py-3">
                  <GoogleMapComponent lat={this.props.lat} lng={this.props.lng}/>
                </div> */}
                <div className="col-6">
                  <Address address={this.props.address} dealer={this.props.dealer} gmbLink={this.props.gmbLink}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MapAndAddress
